import React from 'react';
import { Box } from 'grommet';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';
import { OfferCatalog } from 'schema-dts';
import Layout from '../components/Layout/Layout';
import JsonLd from '../utils/json-ld';

export const frontmatter = {
  title: 'Leistungen',
  menu: 3,
};

const items = [
  {
    title: 'Rezepte',
    description:
      'Fast 90% Ihrer verordneten Medikamente halten wir für Sie ständig an Lager, weitere Mittel können innerhalb weniger Stunden besorgt werden.',
    imageKey: 'rezepte',
  },
  {
    title: 'Selbstmedikation',
    description:
      'Bei leichteren Beschwerden beraten wir Sie umfassend über die Unterstützung Ihrer Genesung mit Arzneimitteln, aber auch Hausmitteln und Verhaltenstipps.',
    imageKey: 'selbstmedikation',
  },
  {
    title: 'Alternative Medizin',
    description:
      'Für eine schonende Therapie beraten wir Sie auch über die Möglichkeiten der Homöopathie, der Schüssler-Salze (Biochemie) oder Bach-Blüten.',
    imageKey: 'alternative-medizin',
  },
  {
    title: 'Rezepturen',
    description:
      'Wir fertigen für Sie individuelle Mischungen wie Cremes, Salben, Lösungen, Kapseln oder Tees.',
    imageKey: 'rezepturen',
  },
  {
    title: 'Körperpflege',
    description:
      'Sie erhalten bei uns hochwertige medizinische Kosmetika und Körperpflegeprodukte.',
    imageKey: 'koerperpflege',
  },
  {
    title: 'Rezept- und Lieferservice',
    description:
      'Geben Sie uns bitte Bescheid, wenn wir Ihre Rezepte beim Arzt holen sollen und Sie einen kostenlosen Bringdienst benötigen.',
    imageKey: 'lieferservice',
  },
  {
    title: 'Arzneimittel-Sprechstunde',
    description:
      'Bei Verdacht auf Risiken oder Nebenwirkungen sowie Fragen zur Anwendung von Arzneimitteln bieten wir Ihnen eine persönliche Arzneimittel-Sprechstunde mit Terminvereinbarung an.',
    imageKey: 'sprechstunde',
  },
  {
    title: 'Medikationsplan',
    description:
      'Wir überprüfen bei Bedarf Ihren Medikationsplan, erstellen eine Medikationsanalyse und setzen uns bei offenen Fragen mit Ihrem Arzt in Verbindung.',
    imageKey: 'medikationsplan',
  },
  {
    title: 'Kauf auf Rechnung',
    description:
      'Unsere Stammkunden können vom Kauf auf Rechnung profitieren. Insbesondere Privatversicherte werden spürbar entlastet, wenn die Rechnungsstellung erst erfolgt, wenn die Erstattung der Krankenkasse bereits auf dem Konto ist.',
    imageKey: 'rechnung',
  },
  {
    title: 'Gesundheitskarte',
    description:
      'Unsere Stammkunden erhalten kostenlos eine Kundenkarte, die mit vielen weiteren Vorteilen für Ihre Gesundheit verbunden ist. Fragen Sie uns danach!',
    imageKey: 'gesundheitskarte',
  },
];

interface ImgList {
  edges: {
    node: ImgNode;
  }[];
}
interface ImgNode {
  name: string;
  childImageSharp: {
    gatsbyImageData: IGatsbyImageData;
  };
}

export default () => {
  const { photos }: { photos: ImgList } = useStaticQuery(graphql`
    {
      photos: allFile(filter: { dir: { regex: "/images/leistungen$/" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 315, height: 200, layout: FIXED)
            }
          }
        }
      }
    }
  `);

  const itemsWithPhotos = items.map((item) => ({
    ...item,
    image: photos.edges.find(({ node }) => node.name === item.imageKey),
  }));

  return (
    <Layout>
      <Helmet>
        <title>Leistungen</title>
      </Helmet>
      <JsonLd<OfferCatalog>>
        {{
          '@context': 'https://schema.org',
          '@type': 'OfferCatalog',
          itemListElement: items.map((item, index) => ({
            '@type': 'ListItem',
            position: index,
            item: {
              '@type': 'Service',
              name: item.title,
              description: item.description,
            },
          })),
        }}
      </JsonLd>
      <h1>Unsere Leistungen</h1>

      <Box direction="row" wrap justify="center">
        {itemsWithPhotos.map((item) => (
          <Item key={item.title}>
            {item.image && (
              <GatsbyImage
                image={item.image.node.childImageSharp.gatsbyImageData}
                alt={item.title}
              />
            )}
            <ItemTextbox>
              <h4>{item.title}</h4>
              <p>{item.description}</p>
            </ItemTextbox>
          </Item>
        ))}
      </Box>
    </Layout>
  );
};

const Item = styled.div`
  overflow: hidden;
  width: 315px;
  max-width: 100%;
  border: 1px solid lightgray;
  margin: 5px;
  border-radius: 5px;
`;

const ItemTextbox = styled.div`
  padding: 3px;
`;
